var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav',{staticStyle:{"justify-content":"space-between"}},[_c('button',{staticClass:"button button-fill",class:{disabled: _vm.activeTab==0},on:{"click":function($event){_vm.tabDisabled((_vm.$refs['main-tabs'].activeIndex-1)+_vm.$refs['main-tabs'].buttons.length%_vm.$refs['main-tabs'].buttons.length)? null : _vm.$refs['main-tabs'].prevTab()}}},[_c('fa-icon',{attrs:{"icon":['fas', 'chevron-left']}}),_c('span',[_vm._v(_vm._s(_vm.$t('back')))])],1),(_vm.activeTab<2)?_c('button',{staticClass:"button button-fill",on:{"click":function($event){_vm.tabDisabled((_vm.$refs['main-tabs'].activeIndex+1)%_vm.$refs['main-tabs'].buttons.length)? null : _vm.$refs['main-tabs'].nextTab()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('next')))]),_c('fa-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1):_vm._e()]),_c('section',[_c('article',{staticStyle:{"height":"436px","width":"675px"}},[_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"0px","left":"0px","height":"435px"},attrs:{"wt":_vm.formData.wt,"buttons":[
          '1. Proveedor',
          '2. Documentos',
          '3. Líneas'
        ],"classButton":function(i){return {disabled: _vm.tabDisabled(i)}},"styleButton":'width:33%;'},on:{"active":_vm.onActiveTab}},[_c('div',{staticClass:"tab"},[_c('fieldset',{staticStyle:{"position":"relative","left":"80px","top":"80px","width":"480px","height":"200px"},attrs:{"model":"devolucion_condicional_compra"}},[_c('legend',[_vm._v("Seleccione proveedor")]),_c('field',{staticStyle:{"top":"47px","left":"70px"},attrs:{"name":"proveedor_id","widget":"m2o","labelProp":"nombre_comercial","width":"320px"}}),_c('field',{staticStyle:{"top":"70px","left":"70px"},attrs:{"name":"devoluciones","widget":"checkbox","label":"Devoluciones","reverse":"","inline-label":""}}),(_vm.showWt)?_c('field',{staticStyle:{"top":"115px","left":"70px"},attrs:{"name":"notas_devoluciones","widget":"checkbox","label":"Prop. de Devoluciones","reverse":"","inline-label":""}}):_vm._e(),_c('field',{staticStyle:{"top":"86px","right":"50px"},attrs:{"name":"desde","type":"date","label":"Desde","width":"100px","inline-label":""}}),_c('field',{staticStyle:{"top":"106px","right":"50px"},attrs:{"name":"hasta","type":"date","label":"Hasta","width":"100px","inline-label":""}})],1)]),_c('div',{staticClass:"tab"},[_c('field',{ref:"documentos_pendientes",staticStyle:{"top":"30px","left":"4px","width":"653px"},attrs:{"name":"documentos_pendientes","widget":"handsontable","height":360,"width":650,"minRows":18,"customprop":"docs_customprop","columns":[
              {name:'sel',header:'Sel.', widget: 'checkbox', help: 'Seleccionado'},
              {name:'tipo_doc',header:'T',readOnly:true, renderer:_vm.wtRenderer},
              {name:'ndoc',header:'NºDocumento',readOnly:true},
              {name:'proveedor_id',header:'Proveedor',readOnly:true,type:'m2o',primary:'codigo',label:'nombre_comercial',showCode:true},
              {name:'fecha',type:'date2',header:'Fecha',readOnly:true},
              {name:'total',header:'Total',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'divisa_id',header:'Divisa',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
              {name:'cambio',header:'Cambio',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}} ]}}),_c('span',{staticStyle:{"position":"absolute","top":"5px","left":"5px"}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Seleccionar todos'),expression:"'Seleccionar todos'"}],attrs:{"name":"sel_all_docs"},on:{"click":_vm.sellAllDocs}},[_c('fa-icon',{attrs:{"icon":['far', 'check-square']}})],1),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Desseleccionar todos'),expression:"'Desseleccionar todos'"}],attrs:{"name":"dessel_all_docs"},on:{"click":_vm.desselAllDocs}},[_c('fa-icon',{attrs:{"icon":['far', 'square']}})],1)])],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"lineas_pendientes",staticStyle:{"top":"5px","left":"4px","width":"653px"},attrs:{"name":"lineas_pendientes","widget":"handsontable","label":"Líneas","height":380,"width":650,"minRows":18,"customprop":"lines_customprop","readonly":"","htSettings":{columnSorting: true},"columns":[
              {name:'ean13',header:'||||||||',readOnly:true},
              {name:'articulo_id',header:'Artículo',readOnly:true, type: 'm2o', primary:'codigo', label:'codigo', noOpen: true, fields:[{name:'thumbnail',fields:['imagen']},'tarifa1','tarifa2','tarifa3','tarifa4','tarifa5','tarifa6','tarifa7','tarifa8','tarifa9','tarifa10']},
              {name:'descripcion',header:'Descripción',readOnly:true},
              {name:'modelo_id',header:'Modelo',type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
              {name:'refproveedor',header:'R. Proveedor',readOnly:true},
              {name:'nota',header:'Nota',readOnly:true},
              {name:'almacen_id',header:'Almacén',readOnly:true, type:'m2o', primary:'codigo', readOnly: true},
              {name:'unidades_pendientes',header:'Unid. pendientes',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'peso_pendiente',header:'Peso pendiente',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.000'}},
              {name:'unidades',header:'Unid.',type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'peso',header:'Peso',type:'numeric',numericFormat: { pattern: '0,0.000'}},
              {name:'c',header:'C',readOnly:true, type:'select', options:['U','P']},
              {name:'m',header:'M',readOnly:true, type:'checkbox'},
              {name:'r',header:'R',readOnly:true, type:'checkbox'},

              {name:'precio',header:'Precio',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'descuentos',header:'Dtos.',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
              {name:'totallinea',header:'Total Línea',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'m2',header:'M',readOnly:true, type:'checkbox'},
              {name:'c2',header:'C',readOnly:true, type:'checkbox'},
              {name:'o',header:'O',readOnly:true, type:'checkbox'},
              {name:'precio_coste',header:'P. Coste',readOnly:true,readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'coste_fijo',header:'Coste Fijo',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'taqueria_ids',header:'Taquería',readOnly:true,type:'m2m',primary:'codigo', image: 'thumbnail.imagen', label: 'codigo', fields:['precio_coste'], filter: function (){ return ['seccion_id','=','PCK']; }},
              {name:'taqueria_precio',header:'Taq.(€)',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'incluir_taqueria',header:'P',readOnly:true, type:'checkbox'},
              {name:'otros_costes',header:'O. Costes',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'v',header:'V',readOnly:true, type:'select', options:['U','P']},

              {name:'m3',header:'M',readOnly:true, type:'checkbox'},
              {name:'margen',header:'%',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
              {name:'pventa',header:'PVP',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'t',header:'T',readOnly:true, type:'select', options:['1','2','3','4','5','6','7','8','9','10']} ]}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }